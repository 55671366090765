export default function extend(scheduler) {


/**
 * helpers for Multisection and Daily timeline events
 * Preserve _sorder and _count calculated for virtual events, so event position won't change when it refreshed individually
 * @private
 */

if (!scheduler._inited_multisection_copies) {
	scheduler.attachEvent("onEventIdChange", function (old_id, new_id) {
		var copies = this._multisection_copies;
		if (!copies) return;

		if (copies[old_id] && !copies[new_id]) {
			var eventCopies = copies[old_id];
			delete copies[old_id];
			copies[new_id] = eventCopies;
		}
	});
	scheduler._inited_multisection_copies = true;
}

scheduler._register_copies_array = function(evs){
	for(var i=0; i < evs.length; i++)
		this._register_copy(evs[i]);
};
scheduler._register_copy = function(copy){
	if(!this._multisection_copies){
		return;
	}

	if(!this._multisection_copies[copy.id]){
		this._multisection_copies[copy.id] = {};
	}
	var section = copy[this._get_section_property()];
	var evs = this._multisection_copies[copy.id];
	evs[section] = copy;
};
scheduler._get_copied_event = function(event_id, section){
	if(!this._multisection_copies[event_id])
		return null;

	if(this._multisection_copies[event_id][section])
		return this._multisection_copies[event_id][section];

	var parts = this._multisection_copies[event_id];
	//multisection event has not been rendered in this section, need retrieve state of one of rendered events
	if(scheduler._drag_event && scheduler._drag_event._orig_section && parts[scheduler._drag_event._orig_section]){
		return parts[scheduler._drag_event._orig_section];
	}else{
		var min_sorder = Infinity,
			ev = null;
		for(var i in parts){
			if(parts[i]._sorder < min_sorder){
				ev = parts[i];
				min_sorder = parts[i]._sorder;
			}
		}
		return ev;
	}
};
scheduler._clear_copied_events = function(){
	this._multisection_copies = {};
};

scheduler._restore_render_flags = function(section_evs){
	var map_to = this._get_section_property();
	for(var i=0; i < section_evs.length; i++){
		var ev = section_evs[i];
		var prev_state = scheduler._get_copied_event(ev.id, ev[map_to]);
		if(prev_state){
			for(var p in prev_state){
				if(p.indexOf("_") === 0){
					ev[p] = prev_state[p];
				}
			}
		}
	}
};

}