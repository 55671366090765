export default (function(){
	return function create(){
		var modes = {
			"minMax":"[0;max]",
			"maxMin":"[max;0]",
			"nMaxMin":"[-max;0]"
		};
		
		function createTestElement(){
			var container = document.createElement("div");
			container.style.cssText = 	"direction: rtl;" + 
										"overflow: auto;" +
										"width:100px;" +
										"height: 100px;" +
										"position:absolute;" +
										"top: -100500px;" +
										"left: -100500px;";
			var content = document.createElement("div");
			content.style.cssText = "width: 100500px;height: 1px;";
			container.appendChild(content);
			return container;
		}

		function detectScrollMode(){
			var result = modes.minMax;
			var demoElement = createTestElement();
			document.body.appendChild(demoElement);

			var initialScroll = demoElement.scrollLeft;
			if(initialScroll > 0){
				// scrollLeft goes from zero (left) to maximum value (right), the same way as in ltr mode
				// probably chrome
				result = modes.minMax;
			}else{
				demoElement.scrollLeft = -50;
				if(demoElement.scrollLeft === -50){
					// scrollLeft goes from negative max (left) to zero (right)
					// probably FF
					result = modes.nMaxMin; 
				}else{
					// scrollLeft goes from max (left) to zero (right)
					// probably IE
					result = modes.maxMin; 
				}
			}

			document.body.removeChild(demoElement);
			return result;
		}

		function normalizeValue(value, scrollWidth){
			var mode = getMode();
			if(mode === modes.nMaxMin){
				if(value){
					return -value;
				}else {
					return 0;
				}
			}else if (mode === modes.minMax){
				return scrollWidth - value;
			}else {
				return value;
			}
		}


		function getScrollValue(element){
			//const direction = "ltr";
			var direction = getComputedStyle(element).direction;
			if(!direction || direction === "ltr"){
				return element.scrollLeft;
			}else {
				var maxScroll = element.scrollWidth - element.offsetWidth;
				return normalizeValue(element.scrollLeft, maxScroll);
			}
		}

		function setScrollValue(element, value){
			//const direction = "ltr";
			var direction = getComputedStyle(element).direction;
			if(!direction || direction === "ltr"){
				element.scrollLeft = value;
			}else {
				var maxScroll = element.scrollWidth - element.offsetWidth;
				var normalized = normalizeValue(value, maxScroll);
				element.scrollLeft = normalized;
			}
		}

		var currentMode;
		function getMode(){
			if(!currentMode){
				currentMode = detectScrollMode();
			}
			return currentMode;
		}
		return {
			modes: modes,
			getMode: getMode,
			normalizeValue: normalizeValue,
			getScrollValue: getScrollValue,
			setScrollValue: setScrollValue
		};
	};
})();