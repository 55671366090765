export default function(scheduler){

scheduler._wa = {};
scheduler.xy.week_agenda_scale_height = 20;
scheduler.templates.week_agenda_event_text = function(start_date, end_date, event, date) {
	return scheduler.templates.event_date(start_date) + " " + event.text;
};
scheduler.date.week_agenda_start = scheduler.date.week_start;
scheduler.date.week_agenda_end = function(date) {
	return scheduler.date.add(date, 7, "day");
};
scheduler.date.add_week_agenda = function(date, inc) {
	return scheduler.date.add(date, inc * 7, "day");
};

scheduler.attachEvent("onSchedulerReady", function() {
	var t = scheduler.templates;
	if (!t.week_agenda_date)
		t.week_agenda_date = t.week_date;
});

(function() {
	var scale_date_format = scheduler.date.date_to_str("%l, %F %d");
	scheduler.templates.week_agenda_scale_date = function(date) {
		return scale_date_format(date);
	};
})();

scheduler.attachEvent("onTemplatesReady", function() {

	var old = scheduler.render_data;
	scheduler.render_data = function(evs) {
		if (this._mode == "week_agenda") {
			scheduler.week_agenda_view(true);
		} else
			return old.apply(this, arguments);
	};

	function renderDay(index){
		return `<div class='dhx_wa_day_cont'>
	<div class='dhx_wa_scale_bar'></div>
	<div class='dhx_wa_day_data' data-day='${index}'></div>
</div>`;
	}

	var fillWeekAgendaTab = function() {
	//	getColumnSizes();
		scheduler._els["dhx_cal_data"][0].innerHTML = '';
		scheduler._rendered = [];
		
		var html = `<div class="dhx_week_agenda_wrapper">
<div class='dhx_wa_column'>
	${renderDay(0)}
	${renderDay(1)}
	${renderDay(2)}
</div>
<div class='dhx_wa_column'>
	${renderDay(3)}
	${renderDay(4)}
	${renderDay(5)}
	${renderDay(6)}
</div>
</div>`;

		var dateElement = scheduler._getNavDateElement();
		if(dateElement){
			dateElement.innerHTML = scheduler.templates[scheduler._mode + "_date"](scheduler._min_date, scheduler._max_date, scheduler._mode);
		}
		
		scheduler._els["dhx_cal_data"][0].innerHTML = html;

		const dayDivs = scheduler.$container.querySelectorAll(".dhx_wa_day_cont");

		scheduler._wa._selected_divs = [];
		var events = scheduler.get_visible_events(); // list of events to be displayed in current week
		var tstart = scheduler.date.week_start(scheduler._date);
		var tend = scheduler.date.add(tstart, 1, "day");
		for (var i = 0; i < 7; i++) {
			dayDivs[i]._date = tstart;
			dayDivs[i].setAttribute("data-date", scheduler.templates.format_date(tstart));

			scheduler._waiAria.weekAgendaDayCell(dayDivs[i], tstart);

			var scale_bar = dayDivs[i].querySelector(".dhx_wa_scale_bar");
			var events_div = dayDivs[i].querySelector(".dhx_wa_day_data");
			scale_bar.innerHTML = scheduler.templates.week_agenda_scale_date(tstart);
			var evs = []; // events which will be displayed in the current day
			for (var j = 0; j < events.length; j++) {
				var tev = events[j];
				if (tev.start_date < tend && tev.end_date > tstart)
					evs.push(tev);
			}
			evs.sort(function(a, b) {
				if (a.start_date.valueOf() == b.start_date.valueOf())
					return a.id > b.id ? 1 : -1;
				return a.start_date > b.start_date ? 1 : -1;
			});
			for (var k = 0; k < evs.length; k++) {
				var ev = evs[k];
				var ev_div = document.createElement('div');
				scheduler._rendered.push(ev_div);
				var ev_class = scheduler.templates.event_class(ev.start_date, ev.end_date, ev);
				ev_div.classList.add('dhx_wa_ev_body');
				if(ev_class){
					ev_div.classList.add(ev_class);
				}
				
				if (scheduler.config.rtl){
					ev_div.classList.add("dhx_wa_ev_body_rtl");
				}
				if (ev._text_style)
					ev_div.style.cssText = ev._text_style;
				if (ev.color)
					ev_div.style.setProperty("--dhx-scheduler-event-background", ev.color);
				if (ev.textColor)
					ev_div.style.setProperty("--dhx-scheduler-event-color", ev.textColor);
				if (scheduler._select_id && ev.id == scheduler._select_id && !(!scheduler.config.week_agenda_select && scheduler.config.week_agenda_select !== undefined)) {
					ev_div.classList.add("dhx_cal_event_selected");
					scheduler._wa._selected_divs.push(ev_div);
				}
				var position = "";
				if (!ev._timed) {
					position = "middle";
					if (ev.start_date.valueOf() >= tstart.valueOf() && ev.start_date.valueOf() <= tend.valueOf())
						position = "start";
					if (ev.end_date.valueOf() >= tstart.valueOf() && ev.end_date.valueOf() <= tend.valueOf())
						position = "end";
				}
				ev_div.innerHTML = scheduler.templates.week_agenda_event_text(ev.start_date, ev.end_date, ev, tstart, position);
				ev_div.setAttribute('event_id', ev.id); // for backward compatibility
				ev_div.setAttribute(scheduler.config.event_attribute, ev.id);

				scheduler._waiAria.weekAgendaEvent(ev_div, ev);

				events_div.appendChild(ev_div);
			}
			tstart = scheduler.date.add(tstart, 1, "day");
			tend = scheduler.date.add(tend, 1, "day");
		}
	};
	scheduler.week_agenda_view = function(mode) {
		scheduler._min_date = scheduler.date.week_start(scheduler._date);
		scheduler._max_date = scheduler.date.add(scheduler._min_date, 1, "week");
		scheduler.set_sizes();
		if (mode) { // mode enabled
			scheduler._table_view = scheduler._allow_dnd = true;
			scheduler.$container.querySelector(".dhx_cal_header").style.display = 'none';

			// cleaning dhx_cal_date from the previous date
			scheduler._els['dhx_cal_date'][0].innerHTML = "";
			fillWeekAgendaTab();
		} else { // leaving week_agenda mode
			scheduler._table_view = scheduler._allow_dnd = false;
			scheduler.$container.querySelector(".dhx_cal_header").style.display = '';
		}
	};
	scheduler.mouse_week_agenda = function(pos) {
		var native_event = pos.ev;

		const dayCont = pos.ev.target.closest(".dhx_wa_day_cont");
		let date;
		if(dayCont){
			date = dayCont._date;
		}

		if (!date)
			return pos;
		pos.x = 0;
		var diff = date.valueOf() - scheduler._min_date.valueOf();
		pos.y = Math.ceil(( diff / (1000 * 60) ) / this.config.time_step);
		if (this._drag_mode == 'move' && this._drag_pos && this._is_pos_changed(this._drag_pos, pos)) {
			var event_div;
			this._drag_event._dhx_changed = true;
			this._select_id = this._drag_id;
			for (var i = 0; i < scheduler._rendered.length; i++) {
				if (scheduler._drag_id == this._rendered[i].getAttribute(this.config.event_attribute))
					event_div = this._rendered[i];
			}
			if (!scheduler._wa._dnd) {
				var div = event_div.cloneNode(true);
				this._wa._dnd = div;
				div.className = event_div.className;
				div.id = 'dhx_wa_dnd';
				div.className += ' dhx_wa_dnd';
				document.body.appendChild(div);
			}
			var dnd_div = document.getElementById('dhx_wa_dnd');
			dnd_div.style.top = ((native_event.pageY || native_event.clientY) + 20) + "px";
			dnd_div.style.left = ((native_event.pageX || native_event.clientX) + 20) + "px";
		}
		return pos;
	};
	scheduler.attachEvent('onBeforeEventChanged', function(event_object, native_event, is_new) {
		if (this._mode == 'week_agenda') {
			if (this._drag_mode == 'move') {
				var dnd = document.getElementById('dhx_wa_dnd');
				dnd.parentNode.removeChild(dnd);
				scheduler._wa._dnd = false;
			}
		}
		return true;
	});

	scheduler.attachEvent("onEventSave", function(id, data, is_new_event) {
		if (is_new_event && this._mode == 'week_agenda')
			this._select_id = id;
		return true;
	});

	scheduler._wa._selected_divs = [];

	scheduler.attachEvent("onClick", function(event_id, native_event_object) {
		if (this._mode == 'week_agenda' && !(!scheduler.config.week_agenda_select && scheduler.config.week_agenda_select !== undefined)) {
			if (scheduler._wa._selected_divs) {
				for (var i = 0; i < this._wa._selected_divs.length; i++) {
					var div = this._wa._selected_divs[i];
					div.className = div.className.replace(/ dhx_cal_event_selected/, '');
				}
			}
			this.for_rendered(event_id, function(event_div) {
				event_div.className += " dhx_cal_event_selected";
				scheduler._wa._selected_divs.push(event_div);
			});
			// scheduler.select(event_id);
			// not using .select(), as it triggers redraw of the view and resets the state of possible scrolls in day cells
			scheduler._select_id = event_id;
			return false;
		}
		return true;
	});
});


}